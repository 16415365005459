import * as React from 'react';

import { Footer, GoogleNoIndex, NavMenu, PageTitle } from '../components/shared';

import { Container } from 'react-bootstrap';

const NoticeExpiredCountiesPage: React.FunctionComponent = () => {
  return (
    <>
      <PageTitle />
      <GoogleNoIndex />
      <NavMenu solidColor notFixed />
      <Container fluid="lg" className="content mt-5">
        <h3>You may still be able to Protest!</h3>
        <div>
          The Texas property tax protest deadline is May 15th, <span className="fw-bold text-decoration-underline">unless</span> you were noticed late.
        </div>
        <div className="fst-italic mb-4">
          (Some counties allow you to file online until midnight and in others your protest must be mailed and postmarked by May 15th)
        </div>

        <h3>Per the Texas Property Tax Code:</h3>
        <div>The deadline to protest property values is May 15. Or 30 days after the appraisal district mails notice of appraised value.</div>
        <div className="fw-bold">Please review your value notice to see if you are still eligible to file a protest.</div>
        <a href="/downloads/50-132.pdf" target="_blank">
          Download the Notice of Protest Form Here
        </a>
      </Container>
      <Footer sticky />
    </>
  );
};

export default NoticeExpiredCountiesPage;
